import { graphql } from "gatsby";

import { Layout } from "../components/Layout";
import { Head } from "../components/Head";

import { MediaImage } from "../common/Media";
import {
  SectionContainer,
  SectionWrapper
} from "../common/Section";

import { CallToAction } from "../components/CallToAction";
import { PestCard } from "../components/Card";
import { ServiceCarousel, TestimonialsCarousel } from "../components/Carousel";
import { TermitesFlexbox } from "../components/Flexbox";
import { CommonPestsGrid } from "../components/Grid";
import { PageHero } from "../components/Hero";


interface Props {
  data: any
};


const ServicesPage = ({
  data
}: Props ) => {
  const pests = data?.allPrismicPest?.edges || [];
  const services = data?.allPrismicService?.edges || [];
  const socials = data?.allPrismicSocial?.edges || [];
  const testimonials = data?.allPrismicTestimonial?.edges || [];

  const calltoaction = data?.prismicCallToAction?.data || {};
  const global = data?.prismicSiteInfo?.data || {};

  const servicespage = data?.prismicServicesPage?.data || {};
  
  return (
    <Layout
      global={ global }
      socials={ socials }
    >
      <Head
        title={ servicespage.title.text }
        description={ servicespage.description.text }
      />

      <SectionWrapper
        background="linear-gradient(#FBF7F1 0%, #FBF7F1 80%, white 80%, white 100%)"
        padding="0 0 45px"
      >
        <SectionContainer>
          <PageHero
            HeroComponent={
              <MediaImage
                image={ servicespage.hero_image.gatsbyImageData }
                alt={ servicespage.hero_image.alt }
                containerWidth={{
                  base: '100%',
                  lg: '668px',
                  xl: '840px',
                  '2xl': '1012px'
                }}
                containerHeight={{
                  base: 'auto',
                  lg: '398px',
                  xl: '500px',
                  '2xl': '602px'
                }}
                borderRadius="14px"
              />
            }
            heading={ servicespage.hero_heading.text }
            description={ servicespage.hero_description.html }
          />
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        padding="50px 0 39px"
      >
        <ServiceCarousel
          heading={ servicespage.services_heading.text }
          description={ servicespage.services_description.html }
          nodes={ services }
        />
      </SectionWrapper>

      <SectionWrapper
        background="linear-gradient(white 0%, white 57%, #F94E1911 57%, #F94E1911 100%)"
        padding="64px 0 57px"
      >
        <SectionContainer>
          <CommonPestsGrid
            heading={ servicespage.pests_heading.text }
            description={ servicespage.pests_description.html }
          >
            { pests.map(( node: any, _index: number ) => (
              <PestCard
                key={ node.node.uid }
                data={ node.node.data }
              />
            ))}
          </CommonPestsGrid>
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        background="background.linkwater"
        padding="155px 0"
      >
        <SectionContainer>
          <TermitesFlexbox
            direction="row"
            heading={ servicespage.termites_heading.text }
            description={ servicespage.termites_description.html }
            image={ servicespage.termites_image.gatsbyImageData }
            alt={ servicespage.termites_image.alt }
            imageBackground="white"
            imageMaxW={{
              base: '80%',
              sm: '320px',
              md: '100%'
            }}
          />
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        padding="80px 0 46px"
      >
        <TestimonialsCarousel
          heading={ servicespage.testimonials_heading.text }
          description={ servicespage.testimonials_description.html }
          nodes={ testimonials }
        />
      </SectionWrapper>

      <SectionWrapper
        padding="60px 0"
      >
        <SectionContainer>
        <CallToAction
            heading={ calltoaction.heading.text }
            description={ calltoaction.description.html }
          />
        </SectionContainer>
      </SectionWrapper>
    </Layout>
  )
}


export const query = graphql`
  query ServicesPageQuery {
    allPrismicPest(
      filter: {
        data: {
          featured: {
            eq: true
          }
        }
      }
      sort: {
        fields: data___title___text
      }
    ) {
      edges {
        node {
          _previewable
          data {
            title {
              text
            }
            image {
              gatsbyImageData(
                placeholder: BLURRED
              )
            }
          }
          uid
        }
      }
    }

    allPrismicService {
      edges {
        node {
          _previewable
          data {
            title {
              text
            }
            description {
              text
            }
            hero_image {
              gatsbyImageData(
                placeholder: BLURRED
              )
            }
          }
          uid
        }
      }
    }

    allPrismicSocial {
      edges {
        node {
          _previewable
          data {
            platform
            link {
              url
            }
          }
          uid
        }
      }
    }

    allPrismicTestimonial {
      edges {
        node {
          _previewable
          data {
            author {
              text
            }
            review {
              text
            }
            source
          }
        }
      }
    }

    prismicCallToAction {
      _previewable
      data {
        heading {
          text
        }
        description {
          html
        }
      }
      uid
    }

    prismicSiteInfo {
      _previewable
      data {
        phone_number {
          text
        }
        phone_number_link {
          url
        }

        email {
          text
        }
        email_link {
          url
        }

        address {
          text
        }

        accreditations {
          image {
            alt
            gatsbyImageData(
              height: 40
              placeholder: BLURRED
            )
          }
        }

        copyright {
          text
        }
      }
      uid
    }

    prismicServicesPage {
      _previewable
      data {
        title {
          text
        }
        description {
          text
        }

        hero_heading {
          text
        }
        hero_description {
          html
        }
        hero_image {
          alt
          gatsbyImageData(
            placeholder: BLURRED
          )
        }

        services_heading {
          text
        }
        services_description {
          html
        }

        pests_heading {
          text
        }
        pests_description {
          html
        }

        termites_heading {
          text
        }
        termites_description {
          html
        }
        termites_image {
          alt
          gatsbyImageData(
            placeholder: BLURRED
          )
        }

        testimonials_heading {
          text
        }
        testimonials_description {
          html
        }
      }
    }
  }
`;


export default ServicesPage;
